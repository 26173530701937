<template>

    <div v-if="isDebug">

    <div @click="logInfo"> info</div>
    <div @click="connectWallet"> connect</div>
    <div @click="disconnectWallet"> DISconnect </div>

    address {{address}}<br>
    chainId {{chainId}}<br>
    workChain {{workChain}}<br>
    isConnected {{isConnected}}<br>
    isCorrectConnection {{isCorrectConnection}}<br>
    isCorrectChain {{isCorrectChain}}<br>

    </div>

    <!-- -->
    <div class="fusion-layout-column fusion_builder_column fusion-builder-column-8 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style="--awb-bg-size:cover" data-scroll-devices="small-visibility,medium-visibility,large-visibility">
        <div class="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy">
            <div class="fusion-text fusion-text-9">
                <h2 style="text-align:center;--fontSize:28;line-height:1.2" data-fontsize=28 data-lineheight="33.6px" class="fusion-responsive-typography-calculated">
                    Rewards program
                </h2>
            </div>

            <div class=fusion-sep-clear></div>
            <div class="fusion-separator fusion-full-width-sep" style=margin-left:auto;margin-right:auto;margin-top:10px;margin-bottom:10px;width:100%></div>
            <div class=fusion-sep-clear></div>
            <div class="fusion-text fusion-text-10">
                <h4 v-if="isCorrectConnection" style="text-align:center;--fontSize:13;line-height:1.7;--minFontSize:13" data-fontsize=13 data-lineheight="22.1px" class="fusion-responsive-typography-calculated">
                    <span class="fusion-highlight custom-textcolor highlight1 awb-highlight-background rounded" style="--awb-color:#eddc44;--awb-text-color:#000000">
                        {{address}}
                    </span>
                </h4>
            </div>

            <div class=fusion-aligncenter>
                <a class="fusion-button button-flat button-xlarge button-default fusion-button-default button-2 fusion-button-default-span fusion-button-default-type" style=--button_margin-top:30px rel="noopener noreferrer"
                   @click="processConnectButton()"
                >
                    <span class="fusion-button-text fusion-button-text-right">
                        {{ !isConnected? "Connect WALLET": !isCorrectChain? "Need change Network" : "Disconnect" }}
                    </span>
                    <span v-if="isCorrectConnection" class="fusion-button-icon-divider button-icon-divider-right">
                        <i class="fa-lock fas"></i>
                    </span>
                    <span v-else-if="!isCorrectChain"  class="fusion-button-icon-divider button-icon-divider-right">
                        <i class="fa-unlock fas"></i>
                    </span>
                    <span v-else  class="fusion-button-icon-divider button-icon-divider-right">
                        <i class="fa-unlock fas" aria-hidden=true></i>
                    </span>

                </a>
            </div>
            <div class=fusion-clearfix></div>
        </div>
    </div>

    <div v-if="isCorrectConnection" class="fusion-layout-column fusion_builder_column fusion-builder-column-9 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style="--awb-padding-top:30px;--awb-padding-right:20px;--awb-padding-bottom:30px;--awb-padding-left:20px;--awb-overflow:hidden;--awb-bg-image:var(--sf-img-16);--awb-bg-position:center center;--awb-bg-size:cover;--awb-border-color:#000000;--awb-border-style:solid;--awb-border-radius:20px 20px 20px 20px;--awb-margin-top:40px" data-scroll-devices=small-visibility,medium-visibility,large-visibility>
        <div class="fusion-column-wrapper fusion-column-has-shadow fusion-flex-column-wrapper-legacy fusion-column-has-bg-image" data-bg-url=https://gramslamscrypto.com/wp-content/uploads/2024/08/bb-light.jpg>
            <div class="fusion-text fusion-text-11">
                <h2 style=text-align:center;--fontSize:28;line-height:1.2 data-fontsize=28 data-lineheight=33.6px class=fusion-responsive-typography-calculated>
                    Get your token holder reward
                </h2>
            </div>
            <div class="fusion-text fusion-text-8">
                <h4 style="text-align:center;--fontSize:18;line-height:2.2" data-fontsize=28 data-lineheight="33.6px" class="fusion-responsive-typography-calculated">
                    Total rewards quantity in program: {{ cryptoWallet.data.rewardsTotal }} <span style="color:#ed1823">pcs</span>
                </h4>
            </div>
            <div class="fusion-text fusion-text-8">
                <h4 style="text-align:center;--fontSize:18;line-height:2.2" data-fontsize=28 data-lineheight="33.6px" class="fusion-responsive-typography-calculated">
                    Processed and received rewards by you: {{ cryptoWallet.data.rewardsProcessed }} <span style="color:#ed1823">pcs</span>
                </h4>
            </div>
            <div class="fusion-text fusion-text-8">
                <h4 style="text-align:center;--fontSize:18;line-height:2.2" data-fontsize=28 data-lineheight="33.6px" class="fusion-responsive-typography-calculated">
                    Your current balance: {{ cryptoWallet.balance }} <span style="color:#ed1823">GSLAM</span>
                </h4>
            </div>
            <div class=fusion-aligncenter>
                <a class="fusion-button button-flat button-xlarge button-default fusion-button-default button-3 fusion-button-default-span fusion-button-default-type" style=--button_margin-top:30px rel="noopener noreferrer" @click="connect">
                    <span class="fusion-button-text fusion-button-text-right">
                        Check & Withdraw Reward
                    </span>
                    <span class="fusion-button-icon-divider button-icon-divider-right">
                        <i class="fa-play fas" aria-hidden=true></i>
                    </span>
                </a>
            </div>
            <div class=fusion-clearfix></div>
        </div>
    </div>

</template>

<script setup>

    import {ref, watch, onMounted} from 'vue'
    import cryptoWallet from './../store/wallet.module'
    import { useWeb3Modal } from '@web3modal/ethers/vue'
    import { useWeb3ModalAccount } from '@web3modal/ethers/vue'
    import { useDisconnect } from '@web3modal/ethers/vue'

    const isCorrectChain = ref(false);
    const isCorrectConnection = ref(false);
    const isDebug = ref(false);

    cryptoWallet;

    const workChain = process.env.VUE_APP_NETWORKS_DEFAULT * 1;
    workChain;

    // const modal = useWeb3Modal();
    // modal;
    const { open } = useWeb3Modal();
    //open;
    //close;

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    address;
    chainId;
    isConnected;

    const { disconnect } = useDisconnect();

    const connectWallet = () => {
        open();
    }
    connectWallet;

    const disconnectWallet = () => {
        disconnect();
    }
    disconnectWallet;

    const changeNetwork = () => {
        open({ view: 'Networks' });
    }
    changeNetwork;

    const logInfo = () => {
        console.log(chainId.value, address.value, isConnected.value);
    }
    logInfo;

    const processConnectButton = () => {
        console.log("processConnectButton", isConnected.value);
        !isConnected.value? connectWallet(): !isCorrectChain.value? changeNetwork() : disconnectWallet();
    }
    processConnectButton;

    watch([chainId, isConnected], ([_chain, _is]) => {
        checkFlags(_chain, _is);
        console.log("watch", _chain, _is);
    })

    const checkFlags = (_chain, _is) => {
        console.log("checkFlags", _chain, _is);
        if(_is && _chain == workChain)
            isCorrectChain.value = true;
        else if(!_is)
            isCorrectChain.value = true;
        else
            isCorrectChain.value = false;

        if(_is && isCorrectChain.value)
            isCorrectConnection.value = true;
        else
            isCorrectConnection.value = false;

        cryptoWallet.getBalance();

    }

    onMounted(() => {
        checkFlags(chainId.value, isConnected.value);
    })



</script>

<style scoped>

</style>
