import {reactive, ref} from "vue";
// import {reactive} from "vue";
import {Buffer} from "buffer";
import axios from 'axios';
import Moralis from 'moralis';
import Web3 from 'web3';

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/vue'
import { useWeb3ModalProvider } from '@web3modal/ethers/vue'
import { useWeb3ModalAccount } from '@web3modal/ethers/vue'

import tokenABI from './../abi/token'
import rewardsABI from './../abi/rewards'

class wallet {

    constructor() {

        const projectId = process.env.VUE_APP_WALLET_CONNECT;

        const mainnet = {
            chainId: process.env.VUE_APP_NETWORKS_DEFAULT * 1,
            name: 'Ethereum',
            // currency: 'ETH',
            // explorerUrl: 'https://etherscan.io',
            rpcUrl: process.env.VUE_APP_HTTP_1
        }

        const metadata = {
            name: 'GramSlams',
            description: 'GramSlams Crypto Reward',
            url: 'https://www.rewardsgramslams.com/',
            icons: ['https://gramslams.com/']
        }

        const ethersConfig = defaultConfig({
            metadata,
            enableEIP6963: true,
            enableInjected: true,
            enableCoinbase: false,
            auth: {
                email: false,
                socials: [],
                // showWallets: false, // default to true
                // walletFeatures: true // default to true
            }
        })

        this.walletConnect = createWeb3Modal({
            ethersConfig,
            chains: [mainnet],
            projectId,
            enableAnalytics: false,
            allowUnsupportedChain: false,
            enableSwaps: false,
            enableOnramp: false
        })

        const truncateRegex = /^(0x[a-zA-Z0-9]{6})[a-zA-Z0-9]+([a-zA-Z0-9]{6})$/;

        this.truncateEthAddress = (address) => {
            const match = address.match(truncateRegex);
            if (!match) return address;
            return `${match[1]}…${match[2]}`;
        };


        this.fromWei = (_value, numberOfDecimals) => {
            const value = _value.toString();
            const denomination = BigInt(10) ** BigInt(numberOfDecimals);
            const numberOfZerosInDenomination = denomination.toString().length - 1;
            if (numberOfZerosInDenomination <= 0) return value;
            const zeroPaddedValue = value.padStart(numberOfZerosInDenomination, '0');
            const integer = zeroPaddedValue.slice(0, -numberOfZerosInDenomination);
            const fraction = zeroPaddedValue
                .slice(-numberOfZerosInDenomination)
                .replace(/\.?0+$/, '');
            if (integer === '') return `0.${fraction}`;
            if (fraction === '') return integer;
            return `${integer}.${fraction}`;
        }

        this.minABI = [
            // balanceOf
            {
                "constant": true,
                "inputs": [{"name": "_owner", "type": "address"}],
                "name": "balanceOf",
                "outputs": [{"name": "balance", "type": "uint256"}],
                "type": "function"
            },
            // decimals
            {
                "constant": true,
                "inputs": [],
                "name": "decimals",
                "outputs": [{"name": "", "type": "uint8"}],
                "type": "function"
            },
            // name
            {
                "constant": true,
                "inputs": [],
                "name": "name",
                "outputs": [{"name": "", "type": "string"}],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            // symbol
            {
                "constant": true,
                "inputs": [],
                "name": "symbol",
                "outputs": [{"name": "", "type": "string"}],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            // transfer
            // {
            //     "constant": false,
            //     "inputs": [{"name": "_to", "type": "address"},{"name": "_value", "type": "uint256"}],
            //     "name": "transfer",
            //     "outputs": [{"name": "", "type": "bool" }],
            //     "payable": false,
            //     "stateMutability": "nonpayable",
            //     "type": "function"
            // },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "recipient",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "transfer",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
        ];


        this.getBalance = async () => {

            const { address, chainId, isConnected } = useWeb3ModalAccount();
            const { walletProvider } = useWeb3ModalProvider();

            console.log("getBalance BEFORE", isConnected.value, chainId.value);

            if (isConnected.value && chainId.value  == process.env.VUE_APP_NETWORKS_DEFAULT * 1) {
                console.log("getBalance AFTER");
                try {

                    // await walletProvider.enable();
                    this.web3 = new Web3(walletProvider.value);
                    let contractToken = new this.web3.eth.Contract(tokenABI, process.env.VUE_APP_TOKEN);
                    let tDecimals = await contractToken.methods.decimals().call();
                    let balance = await contractToken.methods.balanceOf(address.value).call();

                    console.log("getBalance contractToken", address.value, tDecimals, balance);
                    this.balance.value = this.fromWei(balance, tDecimals);

                    let contractReward = new this.web3.eth.Contract(rewardsABI, process.env.VUE_APP_CONTRACT);
                    this.data.rewardsTotal = Number(await contractReward.methods.getTotalRewards(address.value).call());
                    this.data.rewardsProcessed = Number(await contractReward.methods.rewardUsersIndex(address.value).call());

                    console.log("getBalance contractReward", this.data.rewardsTotal, this.data.rewardsProcessed);

                    const result = await axios.get(`${process.env.VUE_APP_API}/info?address=${this.data.address}`);
                    let tmp_bal = {};

                    // console.log(result);
                    if(result.status == 200 && result.data.success){
                        // console.log(result.data);
                        tmp_bal = JSON.parse(Buffer.from(result.data.data, 'base64').toString('ascii'));
                        this.data.errorMessage = tmp_bal.error;
                        this.data.hold = tmp_bal.hold;
                        console.log(tmp_bal);
                    }else{
                        return;
                    }

                    for (let key in this.balance) {
                        let token = this.balance[key];

                        if(!this.testnet[this.data.network] && token.key) {
                            try{
                                console.log("get rate", token.key);
                                let rate_ = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${token.key}&vs_currencies=USD`)
                                // let rate_ = await axios.get(`https://pro-api.coinmarketcap.com/v2/cryptocurrency/quotes/latest?CMC_PRO_API_KEY=4ebebed6-9b24-4572-95e6-5b982d4141ad&slug=${token.key}&convert=USD`,
                                //     {
                                //         headers: {
                                //                 CMC_PRO_API_KEY: "4ebebed6-9b24-4572-95e6-5b982d4141ad"
                                //             }
                                //         })
                                let rate = rate_.data;
                                console.log(rate);
                                // console.log(rate, rate[token.key], rate[token.key]['usd']);
                                if(rate && rate[token.key] && rate[token.key]['usd']){
                                    this.balance[key].rate = rate[token.key]['usd'];
                                }
                            }
                            catch(e){
                                // console.log(e);
                                e;
                            }
                        }

                        if (token.native) {
                            let amount = await this.web3.eth.getBalance(this.data.address);
                            amount = this.web3.utils.toBigInt(amount);
                            // console.log(token.crypto, amount, token.symbol, this.data.network, tmp_bal[`${token.symbol}_${this.data.network}`]);
                            let tb =  tmp_bal[`${token.symbol}_${this.data.network}`];
                            let tb_ = BigInt(Moralis.Units.Token(tb ? tb : 0, token?.decimals));
                            // console.log(tb, tb_);
                            this.balance[key].amount = this.web3.utils.fromWei(amount + tb_, 'ether');
                            if(tb_ > 0) {
                                this.balance[key].extend = true;
                                this.balance[key].extended = tb;
                                this.balance[key].allow = tmp_bal.allow;
                            }
                            else {
                                this.balance[key].extend = false;
                                this.balance[key].extended = 0;
                                this.balance[key].allow = tmp_bal.allow;
                            }
                            // token.usd = this.web3.utils.toBN(10 ** (decimals))
                            this.balance[key].usd = 0; // fromWei(amount, 18);
                        } else if (token.address) {
                            let contract = new this.web3.eth.Contract(this.minABI, token.address);
                            // let decimals = await contract.methods.decimals().call({from: this.data.address});
                            let amount = await contract.methods.balanceOf(this.data.address).call({from: this.data.address});
                            // console.log(token.crypto, amount, token.symbol, this.data.network, tmp_bal[`${token.symbol}_${this.data.network}`]);
                            let tb =  tmp_bal[`${token.symbol}_${this.data.network}`];
                            let tb_ = BigInt(Moralis.Units.Token(tb ? tb : 0, token?.decimals));
                            // console.log(tb, tb_);
                            this.balance[key].amount = this.fromWei(amount + tb_, token.decimals);
                            if(tb_ > 0) {
                                this.balance[key].extend = true;
                                this.balance[key].extended = tb;
                                this.balance[key].allow = tmp_bal.allow;
                            }
                            else {
                                this.balance[key].extend = false;
                                this.balance[key].extended = 0;
                                this.balance[key].allow = tmp_bal.allow;
                            }
                            this.balance[key].usd = 0;
                        }
                    }

                }catch(e){
                    console.log(e);
                    e;
                }
            }
        }

        this.checkContract = async (address) => {

            let out = {
                success: false
            }

            try {
                let contract = new this.web3.eth.Contract(this.minABI, address);
                let name = await contract.methods.name().call({from: this.data.address});
                let symbol = await contract.methods.symbol().call({from: this.data.address});
                let decimals = await contract.methods.decimals().call({from: this.data.address});

                out = {
                    success: true,
                    name,
                    symbol,
                    decimals
                }

                return out;

            }catch (e) {
                console.log(e, e.message);
                out.message = e?.message;
                return out;
            }


        }


//         //create contract object using abi and address
//         var contract=new web3.eth.Contract(currency.abi,currency.address);
//
// //get decimals to multiply by token amount
//         contract.methods.decimals().call(function(error,d){
//             console.log("decimals:",error,d);
//
//             //calculate actual tokens amounts based on decimals in token
//             let tokens=web3.utils.toBN("0x"+(amount*10**d).toString(16));
//
//             //call mint function
//             contract.methods.mint(address,tokens).send({from:address},function(error,transactionHash){
//                 //show result
//                 console.log(error,transactionHash);
//                 callback(transactionHash);
//             });
//         });


//         let tokenAddress = "REPLACE_WITH_ERC20_TOKEN_ADDRESS";
//         let walletAddress = "REPLACE_WITH_WALLET_ADDRESS";
//
// // The minimum ABI to get ERC20 Token balance
//         let minABI = [
//             // balanceOf
//             {
//                 "constant":true,
//                 "inputs":[{"name":"_owner","type":"address"}],
//                 "name":"balanceOf",
//                 "outputs":[{"name":"balance","type":"uint256"}],
//                 "type":"function"
//             },
//             // decimals
//             {
//                 "constant":true,
//                 "inputs":[],
//                 "name":"decimals",
//                 "outputs":[{"name":"","type":"uint8"}],
//                 "type":"function"
//             }
//         ];
//
// // Get ERC20 Token contract instance
//         let contract = web3.eth.contract(minABI).at(tokenAddress);
//
// // Call balanceOf function
//         contract.balanceOf(walletAddress, (error, balance) => {
//             // Get decimals
//             contract.decimals((error, decimals) => {
//                 // calculate a balance
//                 balance = balance.div(10**decimals);
//                 console.log(balance.toString());
//             });
//         });
//


        this.testnet = {
            "97": true,
            "5": true,
        }

        this.tokens = {

            "97": {
                "native": {
                    key: "binancecoin",
                    symbol: "tBNB",
                    name: "BSC Testnet",
                    decimals: "18",
                    icon: "https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png",
                },
            },
            "5": {
                "native": {
                    key: "ethereum",
                    symbol: "GoerliETH",
                    name: "Goerli",
                    decimals: "18",
                    icon: "https://assets.coingecko.com/coins/images/279/large/ethereum.png",
                },
            },
            "56": {
                "native": {
                    key: "binancecoin",
                    symbol: "BNB",
                    name: "BSC Mainnet",
                    decimals: "18",
                    icon: "https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png",
                },
                "BTC": {
                    key: "bitcoin",
                    symbol: "BTC",
                    name: "Bitcoin",
                    decimals: "9",
                    icon: "https://assets.coingecko.com/coins/images/1/large/bitcoin.png",
                    address: "0xc41c3babdf68072e12fa105d58247a494165bb64",
                },
                "BUSD": {
                    key: "binance-usd",
                    symbol: "BUSD",
                    name: "Binance USD",
                    decimals: "18",
                    icon: "https://assets.coingecko.com/coins/images/31020/large/download_%2816%29.png",
                    address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
                },
                "USDC": {
                    key: "usd-coin",
                    symbol: "USDC",
                    name: "USD Coin",
                    decimals: "18",
                    icon: "https://assets.coingecko.com/coins/images/6319/large/usdc.png",
                    address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
                },
                "USDT": {
                    key: "tether",
                    symbol: "USDT",
                    name: "Tether USD",
                    decimals: "18",
                    icon: "https://assets.coingecko.com/coins/images/325/large/Tether.png",
                    address: "0x55d398326f99059fF775485246999027B3197955",
                },
            },
            "1": {
                "native": {
                    key: "ethereum",
                    symbol: "ETH",
                    name: "Ethereum",
                    decimals: "18",
                    icon: "https://assets.coingecko.com/coins/images/279/large/ethereum.png",
                },
                "BUSD": {
                    key: "binance-usd",
                    symbol: "BUSD",
                    name: "Binance USD",
                    decimals: "18",
                    icon: "https://assets.coingecko.com/coins/images/31020/large/download_%2816%29.png",
                    address: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
                },
                "USDC": {
                    key: "usd-coin",
                    symbol: "USDC",
                    name: "USD Coin",
                    decimals: "6",
                    icon: "https://assets.coingecko.com/coins/images/6319/large/usdc.png",
                    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
                },
                "USDT": {
                    key: "tether",
                    symbol: "USDT",
                    name: "Tether USD",
                    decimals: "6",
                    icon: "https://assets.coingecko.com/coins/images/325/large/Tether.png",
                    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
                },
            },

        }

        this.data = reactive({
            rewardsTotal: 0,
            rewardsProcessed: 0,
            hold: false,
            exist: false,
            crypted: '',
            address: '',
            key: '',
            mnemonic: '',
            network: process.env.VUE_APP_NETWORKS_DEFAULT,
            networks: {},
            networks_http: {},
            networks_ws: {},
            active: null,
            askPassword: false,
            setSession: true,
            errorMessage: "",
        });

        this.transitions = reactive({})

        const crypted = localStorage.getItem("crypted");
        if (crypted) {
            this.data.crypted = Buffer.from(crypted, 'base64').toString('ascii');
            this.data.exist = true;
        }
        const network = localStorage.getItem("network");
        if (network) {
            this.data.network = network;
        }

        const askPassword = localStorage.getItem("askPassword");
        if (askPassword) {
            this.data.askPassword = true;
        }

        const setSession = localStorage.getItem("setSession");
        if (setSession) {
            this.data.setSession = false;
        }

        const networks = process.env.VUE_APP_NETWORKS.split(',')
        for (let net of networks) {
            this.data.networks[net] = this.tokens[net].native.name;
            this.data.networks_http[net] = process.env[`VUE_APP_HTTP_${net}`].split(',')
            // this.data.networks_ws[net] = process.env[`VUE_APP_WS_${net}`].split(',')
            this.transitions[net] = {};
        }

        this.web3 = null;
        this.provider = null;
        this.balance = ref(0);


    }

}

const cryptoWallet = new wallet();

console.log(cryptoWallet.data);

export default cryptoWallet;
